<template>
    <main>
        <router-view></router-view>
    </main>
</template>

<script>
import store from '../../store'

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch('contents/getAll')
            .then(next)
    },
}
</script>