<template>
  <div>
    <overview-text-box :title="'Kinder & Jugendliche'">
      <div class="cms" v-html="schwerpunkte_kinder"></div>

      <v-button class="button" :type="'link'" :to="{ name: 'abschnitte.schwerpunkte.edit-kinder' }">
        Bearbeiten
      </v-button>
    </overview-text-box>

    <overview-text-box :title="'Elternberatung'">
      <div class="cms" v-html="schwerpunkte_eltern"></div>

      <v-button class="button" :type="'link'" :to="{ name: 'abschnitte.schwerpunkte.edit-eltern' }">
        Bearbeiten
      </v-button>
    </overview-text-box>

    <overview-text-box :title="'Erwachsene'">
      <div class="cms" v-html="schwerpunkte_erwachsene"></div>

      <v-button
        class="button"
        :type="'link'"
        :to="{ name: 'abschnitte.schwerpunkte.edit-erwachsene' }"
      >
        Bearbeiten
      </v-button>
    </overview-text-box>
  </div>
</template>

<script>
import OverviewTextBox from '@/components/OverviewTextBox'
import Button from '@/components/Button'

export default {
  components: {
    OverviewTextBox,
    'v-button': Button,
  },
  computed: {
    schwerpunkte_kinder() {
      return this.$store.getters['contents/schwerpunkte_kinder']
    },
    schwerpunkte_eltern() {
      return this.$store.getters['contents/schwerpunkte_eltern']
    },
    schwerpunkte_erwachsene() {
      return this.$store.getters['contents/schwerpunkte_erwachsene']
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  margin-top: 2rem;
}
</style>
