<template>
  <div class="desktop-menu-component">
    <nav>
      <cms-icon class="cms-icon"></cms-icon>

      <hr class="seperator" />

      <ul class="menu menu--1">
        <li class="menu__item menu__item--1" v-for="menuItem in MenuItems" :key="menuItem.id">
          <router-link class="menu__link menu__link--1" :to="{ name: menuItem.route }">
            <span class="menu__link__icon menu__link__icon--1">
              <component v-if="menuItem.icon" :is="menuItem.icon"></component>
            </span>
            <span class="menu__link__title menu__link__title--1">{{ menuItem.title }}</span>
          </router-link>

          <ul
            class="menu menu--2"
            v-if="menuItem.subroutes && menuItem.route == $route.matched[0].name"
          >
            <li
              class="menu__item menu__item--2"
              v-for="subroute in menuItem.subroutes"
              :key="subroute.id"
            >
              <router-link
                class="menu__link menu__link--2"
                active-class="highlight"
                v-if="subroute.route"
                :to="{ name: subroute.route }"
              >
                <span class="menu__link__icon menu__link__icon--2">
                  <component v-if="subroute.icon" :is="subroute.icon"></component>
                </span>
                <span class="menu__link__title menu__link__title--2">{{ subroute.title }}</span>
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import MenuItems from './MenuItems'

import CmsIcon from './svg/CmsIcon'

export default {
  name: 'DesktopMenuComponent',
  data() {
    return {
      MenuItems,
    }
  },
  components: {
    CmsIcon,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

nav {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 20rem;
  background: $col-gray-900;
  z-index: 400;
}

.cms-icon {
  height: 4rem;
  margin: 6rem auto;
  stroke: $col-primary-400;
}

.seperator {
  height: 2px;
  width: 16rem;
  margin: 0 auto;
  background-color: $col-gray-700;
}

.menu {
  &--1 {
    margin-top: 4rem;
  }

  &--2 {
    padding: 0.75rem 0;
    background-color: $col-black;
    margin-bottom: 1rem;
  }

  &__link {
    display: flex;
    align-items: center;

    &--1 {
      height: 3.5rem;
      padding-left: 2rem;
      border-left: 4px solid transparent;
      vertical-align: middle;
    }

    &--2 {
      height: 3rem;
      padding-left: 3.5rem;
    }

    &__icon {
      margin-right: 1rem;

      svg {
        fill: $col-gray-200;
      }

      &--1 {
        width: 1.75rem;

        svg {
          height: 1.75rem;
        }
      }

      &--2 {
        width: 1.5rem;

        svg {
          height: 1.5rem;
        }
      }
    }

    &__title {
      color: $col-gray-200;
      letter-spacing: $tracking-wider;

      &--1 {
        font-size: 1.125rem;
        font-weight: 400;
      }

      &--2 {
        font-size: 0.9375rem;
        font-weight: 300;
      }
    }

    &--2.highlight {
      .menu__link__icon svg {
        fill: $col-primary-400;
      }

      .menu__link__title {
        color: $col-primary-400;
      }
    }
  }

  &__link:hover {
    .menu__icon svg {
      fill: $col-primary-400;
    }

    .menu__title {
      color: $col-primary-400;
    }
  }
}
</style>
