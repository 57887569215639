<template>
  <div class="form-row-tiptap" :style="`max-width: ${maxw}rem`">
    <EditorMenuBar :editor="editor" v-slot="{ commands, isActive, focused, _menu, getMarkAttrs }">
      <div class="menubar-wrap">
        <div class="menubar" :class="{ 'is-focused': focused }">
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.bold() }"
            @click="commands.bold"
            type="button"
          >
            <fett-icon>Fett</fett-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.italic() }"
            @click="commands.italic"
            type="button"
          >
            <kursiv-icon>Kursiv</kursiv-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.underline() }"
            @click="commands.underline"
            type="button"
          >
            <underline-icon>Unterstreichen</underline-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.link() }"
            @click="showLinkModal(getMarkAttrs('link'))"
            type="button"
          >
            <link-icon>Link einfügen oder ändern</link-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.download() }"
            @click="showDownloadModal(getMarkAttrs('download'))"
            type="button"
          >
            <download-icon>Download einfügen oder ändern</download-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.heading({ level: 4 }) }"
            @click="commands.heading({ level: 4 })"
            type="button"
          >
            <ueberschrift-1-icon>Überschrift 1</ueberschrift-1-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.bullet_list() }"
            @click="commands.bullet_list"
            type="button"
          >
            <bulleted-list-icon>Aufzählung</bulleted-list-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.smallprint() }"
            @click="commands.smallprint"
            type="button"
          >
            <annotation-icon>Anmerkung</annotation-icon>
          </button>
          <button
            class="menu-button"
            :class="{ 'is-active': isActive.paragraph() }"
            @click="commands.paragraph"
            type="button"
          >
            <paragraph-icon>Absatz</paragraph-icon>
          </button>
          <button class="menu-button" @click="commands.undo" type="button">
            <undo-icon>Rückgängig</undo-icon>
          </button>
          <button class="menu-button" @click="commands.redo" type="button">
            <redo-icon>Wiederherstellen</redo-icon>
          </button>
        </div>
      </div>
    </EditorMenuBar>

    <div class="editor-wrap cms">
      <EditorContent :editor="editor" />
    </div>

    <modal-component class="modal modal--link" ref="linkModal">
      <template v-slot:title>Link</template>

      <div class="row">
        <form @submit.prevent="setLinkUrl(linkUrl)">
          <input type="text" v-model="linkUrl" placeholder="https://" ref="linkInput" autofocus />
        </form>
        <button class="save" @click="setLinkUrl(linkUrl)">
          <tick-icon>Link speichern</tick-icon>
        </button>
      </div>
    </modal-component>

    <modal-component class="modal modal--download" ref="downloadModal">
      <template v-slot:title>Download</template>

      <div class="row">
        <form @submit.prevent="setDownloadUrl(downloadUrl)">
          <input type="text" v-model="downloadUrl" placeholder="//" ref="downloadInput" autofocus />
        </form>
        <button class="save" @click="setDownloadUrl(downloadUrl)">
          <tick-icon>Download speichern</tick-icon>
        </button>
      </div>
    </modal-component>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from 'tiptap'
import {
  Italic,
  Bold,
  Underline,
  Heading,
  ListItem,
  BulletList,
  HardBreak,
  History,
} from 'tiptap-extensions'
import Link from '../../tiptap-custom-extensions/CustomLink'
import Download from '../../tiptap-custom-extensions/Download'
import Smallprint from '../../tiptap-custom-extensions/Smallprint'

import {
  FettIcon,
  KursivIcon,
  UnderlineIcon,
  LinkIcon,
  DownloadIcon,
  Ueberschrift1Icon,
  BulletedListIcon,
  AnnotationIcon,
  ParagraphIcon,
  UndoIcon,
  RedoIcon,
} from '../svg/editor-icons'

import ModalComponent from '../ModalComponent'
import TickIcon from '../svg/TickIcon'

export default {
  name: 'FormTipTap',
  components: {
    EditorContent,
    EditorMenuBar,
    ModalComponent,
    FettIcon,
    KursivIcon,
    UnderlineIcon,
    LinkIcon,
    DownloadIcon,
    BulletedListIcon,
    Ueberschrift1Icon,
    AnnotationIcon,
    ParagraphIcon,
    UndoIcon,
    RedoIcon,
    TickIcon,
  },
  props: {
    value: {
      default: '...',
      type: String,
    },
    maxw: {
      default: 48,
      type: Number,
    },
  },
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Bold(),
          new Italic(),
          new Underline(),
          new Link(),
          new Download(),
          new BulletList(),
          new ListItem(),
          new Heading({ levels: [4] }),
          new HardBreak(),
          new Smallprint(),
          new History(),
        ],
        content: this.value,
        onUpdate: ({ getHTML }) => {
          const body = getHTML()
          this.$emit('input', body)
        },
      }),
      linkUrl: null,
      linkMenuIsActive: false,
      downloadUrl: null,
      downloadMenuIsActive: false,
      autosaver: null,
    }
  },
  beforeDestroy() {
    this.editor.destroy()
  },
  methods: {
    showLinkModal(attrs) {
      this.linkUrl = attrs.href
      this.linkMenuIsActive = true
      this.$refs.linkModal.showModal()
    },
    hideLinkModal() {
      this.linkUrl = null
      this.linkMenuIsActive = false
      this.$refs.linkModal.hideModal()
    },
    setLinkUrl(url) {
      this.editor.commands.link({ href: url })
      this.hideLinkModal()
    },
    showDownloadModal(attrs) {
      this.downloadUrl = attrs.href
      this.downloadMenuIsActive = true
      this.$refs.downloadModal.showModal()
    },
    hideDownloadModal() {
      this.downloadUrl = null
      this.downloadMenuIsActive = false
      this.$refs.downloadModal.hideModal()
    },
    setDownloadUrl(url) {
      this.editor.commands.download({ href: url })
      this.hideDownloadModal()
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.form-row-tiptap {
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
}

.menu-button {
  display: inline-block;
  height: 2rem;
  border-radius: 0.375rem;
  cursor: pointer;

  &:not(:first-child) {
    margin-left: 0.25rem;
  }

  &:hover {
    background: $col-gray-200;
  }

  &.is-active {
    background: $col-gray-300;
  }
}

.editor-wrap {
  margin-top: 0.5rem;
  min-height: 4rem;
  padding: 0.5rem;
  border: 1px solid $col-gray-200;
  border-radius: 0.25rem;
  background: $col-white;
  font-size: 100%;
  transition: all ease 250ms;
}

.modal--link,
.modal--download {
  .row {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  form {
    display: inline-block;
    height: 2rem;
    padding: 0 0.5rem;
    border-radius: 0.25rem 0 0 0.25rem;
    border-top: 1px solid $col-gray-300;
    border-bottom: 1px solid $col-gray-300;
    border-left: 1px solid $col-gray-300;

    input {
      width: 16rem;
      line-height: 1.875rem;

      &::placeholder {
        color: $col-gray-600;
      }
    }
  }

  button {
    height: 2rem;

    svg {
      fill: $col-white;
      height: 2rem;
    }
  }

  .save {
    border-radius: 0 0.25rem 0.25rem 0;
    background: $col-primary-500;

    &:hover,
    &:focus {
      background: $col-primary-600;
    }
  }
}
</style>
