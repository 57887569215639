import { render, staticRenderFns } from "./CmsIcon.vue?vue&type=template&id=11b4c04d&scoped=true&"
import script from "./CmsIcon.vue?vue&type=script&lang=js&"
export * from "./CmsIcon.vue?vue&type=script&lang=js&"
import style0 from "./CmsIcon.vue?vue&type=style&index=0&id=11b4c04d&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11b4c04d",
  null
  
)

export default component.exports