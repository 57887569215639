<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Images</slot>
        </title>
        <path d="M27,16V48H12V16H27m1.5-2h-18a.5.5,0,0,0-.5.5v35a.5.5,0,0,0,.5.5h18a.5.5,0,0,0,.5-.5v-35a.5.5,0,0,0-.5-.5Z"/>
        <path d="M25,38.93V46H14V44.31A23.77,23.77,0,0,0,18,43,40.06,40.06,0,0,0,25,38.93Z"/>
        <path d="M25,35.53v.95l-1.16.82a37.9,37.9,0,0,1-6.63,3.86A20.86,20.86,0,0,1,14,42.24V33.71A34.28,34.28,0,0,0,19,35,33.88,33.88,0,0,0,25,35.53Z"/>
        <path d="M52,16V48H37V16H52m1.5-2h-18a.5.5,0,0,0-.5.5v35a.5.5,0,0,0,.5.5h18a.5.5,0,0,0,.5-.5v-35a.5.5,0,0,0-.5-.5Z"/>
        <polygon points="50 27 50 46 39 46 39 39 42 32 44 36 49 25 50 27"/>
        <circle cx="22" cy="24" r="3"/>
    </svg>
</template>

<script>
export default {
    name: 'ImagesIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>