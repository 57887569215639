<template>
    <svg viewBox="0 0 411 411">
        <title>
            <slot>Pages</slot>
        </title>
        <g>
            <path d="M317.9,45.6H143.5a12,12,0,0,0-12,12v29H282a12,12,0,0,1,12,12V310.4h24a12,12,0,0,0,12-12V57.6A12,12,0,0,0,317.9,45.6Z"/>
            <path d="M267.5,100.6H93.1a12,12,0,0,0-12,12V353.4a12,12,0,0,0,12,12H267.5a12,12,0,0,0,12-12V112.6A12,12,0,0,0,267.5,100.6ZM189.4,286.9H120.8a5.7,5.7,0,0,1,0-11.3h68.6a5.7,5.7,0,1,1,0,11.3Zm50.3-30.3H120.8a5.7,5.7,0,1,1,0-11.3H239.7a5.7,5.7,0,1,1,0,11.3Zm0-30.3H120.8a5.7,5.7,0,0,1,0-11.3H239.7a5.7,5.7,0,1,1,0,11.3Zm0-30.3H120.8a5.7,5.7,0,1,1,0-11.3H239.7a5.7,5.7,0,1,1,0,11.3Zm0-30.3H120.8a5.7,5.7,0,1,1,0-11.3H239.7a5.7,5.7,0,1,1,0,11.3Z"/>
        </g>
    </svg>
</template>

<script>
export default {
    name: 'MenuPagesIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>