<template>
    <main>
        <h1>Files</h1>
        
        <router-view></router-view>
    </main>
</template>

<script>
import store from '../../store'

export default {
    beforeRouteEnter(to, from, next) {
        store.dispatch('files/getAll')
            .then(next)
    },
}
</script>