<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Tick</slot>
        </title>
        <path d="M24.4,50.87,12.8,35.4a2,2,0,0,1,.4-2.8l1.6-1.2a2,2,0,0,1,2.8.4L26,43,46.4,15.8a2,2,0,0,1,2.8-.4l1.6,1.2a2,2,0,0,1,.4,2.8L27.6,50.87A2,2,0,0,1,24.4,50.87Z"/>
    </svg>
</template>

<script>
export default {
    name: 'TickIcon',
}
</script>