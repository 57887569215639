<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Menu</slot>
        </title>
        <rect x="12" y="14" width="40" height="2" rx="1"/>
        <rect x="12" y="31" width="40" height="2" rx="1"/>
        <rect x="12" y="48" width="40" height="2" rx="1"/>
    </svg>
</template>

<script>
export default {
    name: 'MenuIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>
