<template>
  <table :class="head">
    <slot></slot>
  </table>
</template>

<script>
export default {
  name: 'VTable',
  props: {
    head: {
      default: 'top', // top / left
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

table {
  margin-bottom: 4rem;

  td,
  th {
    height: 2.5rem;
    padding: 0 1rem;
    vertical-align: middle;
  }

  &.top {
    tr:first-of-type {
      background: $col-gray-900;

      th {
        font-weight: 700;
        color: $col-white;
      }
    }

    tr:not(:first-of-type):not(:last-of-type) {
      border-bottom: 1px solid $col-gray-500;
    }
  }

  &.left {
    tr:not(:last-of-type) {
      border-bottom: 1px solid $col-gray-500;
    }

    th:not(:last-of-type) {
      border-right: 1px solid $col-gray-500;
    }
  }

  td.btns-col {
    padding-left: 4rem;
  }
}
</style>
