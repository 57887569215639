<template>
  <div class="form-box-btns">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormBoxButtons',
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.form-box-btns {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 4rem;
}
</style>
