<template>
  <div>
    <h1>Passwort ändern</h1>

    <v-form :onSubmit="updatePassword">
      <form-error v-if="Object.entries(errors).length !== 0" :width="35">
        Bitte überprüfe deine Eingaben.
      </form-error>

      <form-box-fields>
        <form-row-input
          :name="'current_password'"
          :type="'password'"
          :rqd="true"
          :af="true"
          :lw="16"
          :invalid="!!errors.current_password"
          v-model="formData.current_password"
        >
          Aktuelles Passwort:
        </form-row-input>
        <form-field-error v-if="!!errors.current_password">
          {{ errors.current_password[0] }}
        </form-field-error>

        <form-row-input
          :name="'password'"
          :type="'password'"
          :rqd="true"
          :lw="16"
          :invalid="!!errors.password"
          v-model="formData.password"
        >
          Neues Passwort:
        </form-row-input>
        <form-field-error v-if="!!errors.password">
          {{ errors.password[0] }}
        </form-field-error>

        <form-row-input
          :name="'password_confirmation'"
          :type="'password'"
          :rqd="true"
          :lw="16"
          :invalid="!!errors.password_confirmation"
          v-model="formData.password_confirmation"
        >
          Neues Password wiederholen:
        </form-row-input>
        <form-field-error v-if="!!errors.password_confirmation">
          {{ errors.password_confirmation[0] }}
        </form-field-error>
      </form-box-fields>

      <form-box-buttons>
        <v-button :type="'submit'" :color="'green'">SPEICHERN</v-button>
        <v-button :type="'link'" :color="'light'" :to="{ name: 'home' }">ABBRECHEN</v-button>
      </form-box-buttons>
    </v-form>
  </div>
</template>

<script>
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/Button'

export default {
  components: {
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      formData: {
        current_password: '',
        password: '',
        password_confirmation: '',
      },
      errors: {},
    }
  },
  methods: {
    updatePassword() {
      this.$store
        .dispatch('auth/updatePassword', this.formData)
        .then(this.$router.push({ name: 'account' }))
        .catch(err => (this.errors = err.errors))
    },
  },
}
</script>
