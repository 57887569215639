<template>
  <div>
    <v-table :head="'top'">
      <tr>
        <th>URL</th>
        <th></th>
      </tr>
      <tr v-for="{ id, url } in files" :key="id">
        <td>
          <a :href="url" target="_blank">{{ url }}</a>
        </td>
        <td>
          <table-button :type="'button'" :hoverColor="'red'" @click="deleteFile(id)">
            <delete-icon>Löschen</delete-icon>
          </table-button>
        </td>
      </tr>
    </v-table>
    <v-button class="button" :type="'link'" :to="{ name: 'files.upload' }">Neues File</v-button>
  </div>
</template>

<script>
import TableComponents from '@/components/TableComponents'
import Button from '@/components/Button'

import DeleteIcon from '@/components/svg/DeleteIcon'

export default {
  components: {
    ...TableComponents,
    'v-button': Button,
    DeleteIcon,
  },
  computed: {
    files() {
      return this.$store.getters['files/files']
    },
  },
  methods: {
    deleteFile(id) {
      this.$store.dispatch('files/delete', id)
    },
  },
}
</script>

<style lang="scss" scoped></style>
