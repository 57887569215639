import axios from 'axios'

const getDefaultState = () => {
  return {
    files: [],
  }
}

const state = getDefaultState()

const getters = {
  files: state => state.files,
}

const mutations = {
  setAll: (state, filesArray) => (state.files = filesArray),
}

const actions = {
  getAll: ({ commit }) => {
    return new Promise((resolve, reject) => {
      const url = `/files`
      const config = {}
      axios
        .get(url, config)
        .then(response => {
          commit('setAll', response.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  store: ({ commit }, formData) => {
    return new Promise((resolve, reject) => {
      const data = formData
      const url = `/admin/files`
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      }
      axios
        .post(url, data, config)
        .then(response => {
          commit('setAll', response.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  delete: ({ commit }, id) => {
    return new Promise((resolve, reject) => {
      const url = `/admin/files/${id}`
      axios
        .delete(url)
        .then(response => {
          commit('setAll', response.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
