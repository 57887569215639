import Vue from 'vue'
import Vuex from 'vuex'

import auth from './auth'
import layout from './layout'
import contents from './contents'
import files from './files'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    layout,
    contents,
    files,
  },
})
