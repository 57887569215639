<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Image</slot>
        </title>
        <path d="M52,16V48H12V16H52m1.5-2h-43a.5.5,0,0,0-.5.5v35a.5.5,0,0,0,.5.5h43a.5.5,0,0,0,.5-.5v-35a.5.5,0,0,0-.5-.5Z"/>
        <path d="M50,43.67A71.2,71.2,0,0,1,40,42c-7.65-1.85-10.49-3.87-16-4a25,25,0,0,0-10,1.9V46H50Z"/>
        <path d="M24,36c5.51.13,8.35,2.15,16,4a69.34,69.34,0,0,0,8.5,1.5L37,30l-4,4-9-9L14,35v2.9A25,25,0,0,1,24,36Z"/>
        <circle cx="44" cy="25" r="3"/>
    </svg>
</template>

<script>
export default {
    name: 'ImageIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>