import MenuIcons from './svg/MenuIcons'

export default [
  {
    title: 'Account',
    icon: MenuIcons.MenuAccountIcon,
    route: 'account',
    subroutes: [
      {
        title: 'Hilfe',
        route: 'account.help',
        icon: MenuIcons.MenuHelpIcon,
      },
      {
        title: 'Passwort ändern',
        route: 'account.change-password',
        icon: MenuIcons.MenuPasswordIcon,
      },
      {
        title: 'Logout',
        route: 'account.logout',
        icon: MenuIcons.MenuLogoutIcon,
      },
    ],
  },
  {
    title: 'Abschnitte',
    route: 'abschnitte',
    icon: MenuIcons.MenuPagesIcon,
    subroutes: [
      {
        title: 'Über mich',
        route: 'abschnitte.ueber-mich',
        icon: MenuIcons.MenuPageIcon,
      },
      {
        title: 'Settings',
        route: 'abschnitte.settings',
        icon: MenuIcons.MenuPageIcon,
      },
      {
        title: 'Erstgespräch',
        route: 'abschnitte.erstgespraech',
        icon: MenuIcons.MenuPageIcon,
      },
      {
        title: 'Schwerpunkte',
        route: 'abschnitte.schwerpunkte',
        icon: MenuIcons.MenuPageIcon,
      },
      {
        title: 'Methode',
        route: 'abschnitte.methode',
        icon: MenuIcons.MenuPageIcon,
      },
      {
        title: 'Kontakt',
        route: 'abschnitte.kontakt',
        icon: MenuIcons.MenuPageIcon,
      },
    ],
  },
  {
    title: 'Files',
    route: 'files',
    icon: MenuIcons.MenuPagesIcon,
  },
]
