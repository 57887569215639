<template>
  <div>
    <h1>Hilfe</h1>

    <p>Das CMS besteht aus drei Bereichen:</p>
    <p>
      <strong>Account:</strong>
      Hier findest du Hilfe, Logout und die Möglichkeit, dein Passwort zu ändern.
    </p>
    <p>
      <strong>Abschnitte:</strong>
      Hier kannst du neun verschiedene Textfelder auf deiner Website bearbeiten.
    </p>
    <p>
      <strong>Files:</strong>
      Hier kannst du Dokumente (bspw.: PDFs) hochladen oder entfernen.
    </p>
    <p><br /></p>

    <p><strong>Textbearbeitung</strong></p>
    <p>
      Du hast für jedes der Textfelder einen Editor. Die meisten Funktionen des Editors sollten
      selbsterklärend sein, aber ein paar Dinge sollte ich erklären:
    </p>
    <p>
      <span class="menu-button"><ueberschrift-1-icon></ueberschrift-1-icon></span>
      Für Überschriften (wie bspw. „Auszüge aus meinem Lebenslauf“ in „Über Mich“).
    </p>
    <p>
      <span class="menu-button"><annotation-icon></annotation-icon></span>
      Für kleingedruckten Text (wie bspw. die Supervision-Anmerkung in „Über Mich“).
    </p>
    <p>
      <span class="menu-button"><link-icon></link-icon></span>
      Für Links im Fließtext (wie bspw. in „Über Mich“ und „Methode“).
    </p>
    <p>
      <span class="menu-button"><download-icon></download-icon></span>
      Für Download-Links in einer eigenen Zeile (wie bspw. zu Infomaterial in „Settings - Aktuell“).
    </p>
    <p>
      Wenn du einen Link (egal ob Fließtext-Link oder Download-Link) einfügen willst, dann schreibe
      zuerst den Text, als der der Link angezeigt werden soll (bspw. "hier"). Dann markiere diesen
      Text, klick auf
      <span class="menu-button"><link-icon></link-icon></span>
      oder
      <span class="menu-button"><download-icon></download-icon></span>
      und füge die URL ein. Die URL sollte mit "https://" oder "//" beginnen.
    </p>
    <p><br /></p>

    <p><strong>Files</strong></p>
    <p>
      Zuerst lade das File im Bereich „Files“ hoch. Dann kopiere die generierte URL, die dir in der
      Übersicht angezeigt wird. Du kannst diese URL dann in der Textbearbeitung via
      <span class="menu-button"><link-icon></link-icon></span>
      oder
      <span class="menu-button"><download-icon></download-icon></span>
      verwenden.
    </p>
  </div>
</template>

<script>
import {
  LinkIcon,
  DownloadIcon,
  Ueberschrift1Icon,
  AnnotationIcon,
} from '@/components/svg/editor-icons'

export default {
  components: {
    LinkIcon,
    DownloadIcon,
    Ueberschrift1Icon,
    AnnotationIcon,
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

p {
  max-width: 40rem;
  margin-top: 0.5em;
  line-height: $leading-medium;
}

strong {
  font-weight: 600;
}

.menu-button {
  vertical-align: middle;
  display: inline-block;
  height: 2rem;
  border-radius: 0.375rem;
  transform: translateY(-2px);

  &:not(:first-child) {
    margin-left: 0.25rem;
  }
}
</style>
