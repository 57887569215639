<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Überschrift 1</slot>
        </title>
        <path d="M44.77,21.05C42,21.24,41,22,40.92,23.92c-.06,1.06-.14,2.85-.14,6.72v4.94c0,4.36-.55,7.86-3.09,10.54C35.63,48.27,32.61,49,29.56,49a12,12,0,0,1-7.34-2.08c-2.62-2-3.89-5-3.89-10.09V25.61c0-4-.36-4.25-3.85-4.56v-1.3H26.42v1.3c-3.48.24-3.84.58-3.84,4.56V36.74c0,6.15,2.92,9.62,8.1,9.62,6.07,0,8.11-3.87,8.11-11V30.64c0-3.86-.14-5.52-.23-6.73-.13-2-1.19-2.68-4.41-2.86v-1.3H44.77ZM28.05,14a2.4,2.4,0,0,1-2.38,2.4,2.44,2.44,0,0,1,0-4.88A2.4,2.4,0,0,1,28.05,14ZM37,14a2.42,2.42,0,1,1-2.42-2.48A2.42,2.42,0,0,1,37,14Zm9.24,40.56v-.78c3.11-.2,3.28-.34,3.28-2.44V40.54c0-1.43-.2-1.49-1.43-1.6l-1.37-.14v-.7a22.83,22.83,0,0,0,5.12-1.37V51.29c0,2.13.17,2.24,3.31,2.44v.78Z"/>
    </svg>
</template>

<script>
export default {
    name: 'Ueberschrift1Icon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>