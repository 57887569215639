<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Redo</slot>
        </title>
        <path d="M9.91,36a13,13,0,0,0,13,13h7.5a.5.5,0,0,0,.5-.5v-1a.5.5,0,0,0-.5-.5h-5.5a11,11,0,0,1,0-22h14a19,19,0,0,1-1,5.17c-.29.77,0,1,.71.62,3.85-2.33,11.8-5.21,15-6.32.74-.26.74-.68,0-.94-3.2-1.11-11.15-4-15-6.32-.7-.43-1-.15-.71.62a19,19,0,0,1,1,5.17h-16A13,13,0,0,0,9.91,36Z"/>
    </svg>
</template>

<script>
export default {
    name: 'RedoIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>