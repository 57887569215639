<template>
  <div class="form-row-input">
    <label v-if="label" :for="name" :style="`width: ${lw}rem`">
      <slot></slot>
    </label>
    <input
      :class="[{ invalid: invalid }, `bg-${bg}`]"
      :name="name"
      :type="type"
      :placeholder="ph"
      :value="value"
      :required="rqd"
      :style="`width: ${fw}rem`"
      v-focus="af"
      @input="updateValue($event.target.value)"
    />
  </div>
</template>

<script>
export default {
  name: 'FormRowInput',
  props: {
    name: {
      default: '',
      type: String,
      required: true,
    },
    type: {
      default: 'text', // text / email / password / ...
      type: String,
    },
    label: {
      default: true,
      type: Boolean,
    },
    ph: {
      default: '',
      type: String,
    },
    value: {
      type: String,
    },
    rqd: {
      default: false,
      type: Boolean,
    },
    af: {
      default: false,
      type: Boolean,
    },
    lw: {
      default: 12,
      type: Number,
    },
    fw: {
      default: 16,
      type: Number,
    },
    bg: {
      default: 'light', // light / dark
      type: String,
    },
    invalid: {
      default: false,
      type: Boolean,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
  },
  directives: {
    focus: {
      inserted: function (el, binding) {
        if (binding.value) el.focus()
      },
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.form-row-input {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  align-items: flex-start;
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
}

label {
  justify-self: end;
  height: 2.25rem;
  width: 8rem;
  padding-top: 0.5rem;
  text-align: right;
}

input {
  height: 2.25rem;
  padding: 0.5rem;
  border: 1px solid $col-gray-200;
  border-radius: 0.25rem;
  background: $col-white;
  font-size: 100%;
  transition: all ease 250ms;

  &::placeholder {
    color: $col-gray-600;
  }

  &.bg-dark {
    color: $col-white;
    background: $col-black;

    &::placeholder {
      color: $col-gray-400;
    }
  }

  &:focus,
  &:active {
    border-color: $col-primary-400;
  }

  &.invalid,
  &.invalid:focus,
  &.invalid:active {
    border-color: $col-red-500;
  }
}
</style>
