<template>
  <div
    class="form-box-fields"
    :data-has-title="hasTitle"
    :data-title="title"
    :style="`max-width: ${maxw}rem`"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'FormBoxFields',
  props: {
    title: {
      default: null,
      type: String,
    },
    maxw: {
      default: 48,
      type: Number,
    },
  },
  computed: {
    hasTitle() {
      return !!this.title
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.form-box-fields {
  display: block;
  position: relative;
  margin-top: 0.6875rem;
  padding: 3rem 1rem;
  border: 1px solid $col-gray-300;
  border-radius: 0.5rem;

  &:not(:first-child) {
    margin-top: calc(0.6875rem + 4rem);
  }

  &[data-has-title='true']::after {
    content: attr(data-title);
    display: block;
    position: absolute;
    top: -0.6875rem;
    left: 1.5rem;
    padding: 0.125rem 1rem;
    font-size: 1.125rem;
    letter-spacing: $tracking-wide;
    color: $col-gray-900;
    background: $col-gray-100;
  }
}
</style>
