<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Numbered List</slot>
        </title>
        <rect x="24" y="17" width="24" height="3" rx="0.5"/>
        <rect x="24" y="30" width="24" height="3" rx="0.5"/>
        <rect x="24" y="43" width="24" height="3" rx="0.5"/>
        <path d="M14.76,22.37v-.44c1.23-.06,1.32-.2,1.32-1.06V16.53c0-.61-.22-.66-.71-.72L15,15.75v-.4a12.26,12.26,0,0,0,2.66-.6v6.12c0,.89.07,1,1.3,1.06v.44Z"/>
        <path d="M19.55,33.39c-.19.65-.42,1.48-.54,2H14.17v-.32c.64-.63,1.33-1.34,1.94-2a4.08,4.08,0,0,0,1.31-2.76c0-.85-.42-1.4-1.24-1.4a2.07,2.07,0,0,0-1.63,1.07l-.36-.3L15,28.5a2.38,2.38,0,0,1,1.84-.75A2.1,2.1,0,0,1,19,29.9c0,.94-.36,1.62-1.58,2.75-.47.43-1.14,1.05-1.57,1.42h2c.75,0,.89,0,1.33-.8Z"/>
        <path d="M14.4,42.3l.6-.79a2.27,2.27,0,0,1,1.74-.76,1.75,1.75,0,0,1,2,1.67,1.15,1.15,0,0,1-.4.86,5.58,5.58,0,0,1-.93.65c1.24.25,1.91.93,1.91,1.83,0,1.9-2.64,2.75-3.41,2.75a2.19,2.19,0,0,1-1.33-.39.64.64,0,0,1-.29-.49.81.81,0,0,1,.33-.59.38.38,0,0,1,.49,0,2.45,2.45,0,0,0,1.56.78c.61,0,1.08-.44,1.08-1.56a1.46,1.46,0,0,0-1.5-1.54,2.62,2.62,0,0,0-.66.07l-.09-.42c1.11-.37,1.73-.77,1.73-1.58a1,1,0,0,0-1-1.11,1.81,1.81,0,0,0-1.45.91Z"/>
    </svg>
</template>

<script>
export default {
    name: 'NumberedListIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>