<template>
  <div>
    <v-form :onSubmit="submit">
      <form-box-fields :title="'Hauptartikel'">
        <form-error v-if="Object.entries(errors).length !== 0">
          Bitte überprüfe deine Eingaben.
        </form-error>

        <form-row-tiptap v-model="erstgespraech"></form-row-tiptap>

        <form-box-buttons>
          <v-button :type="'submit'" :color="'green'">SPEICHERN</v-button>
          <v-button :type="'link'" :color="'light'" :to="{ name: 'abschnitte.erstgespraech' }">
            ABBRECHEN
          </v-button>
        </form-box-buttons>
      </form-box-fields>
    </v-form>
  </div>
</template>

<script>
import FormComponents from '@/components/FormComponents'
import VButton from '@/components/Button'

export default {
  components: {
    ...FormComponents,
    VButton,
  },
  data() {
    return {
      erstgespraech: '',
      errors: {},
    }
  },
  created() {
    this.erstgespraech = this.$store.getters['contents/erstgespraech']
  },
  methods: {
    submit() {
      this.$store
        .dispatch('contents/patch', {
          field: 'erstgespraech',
          content: this.erstgespraech,
        })
        .then(this.$router.push({ name: 'abschnitte.erstgespraech' }))
    },
  },
}
</script>
