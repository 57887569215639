<template>
  <div>
    <v-form :onSubmit="submit">
      <form-box-fields :title="'File hochladen'">
        <form-error v-if="Object.entries(errors).length !== 0">
          Bitte überprüfe deine Eingaben.
        </form-error>

        <label for="file"></label>
        <input type="file" name="file" id="file" @change="handleFileUpload" />

        <form-box-buttons>
          <v-button :type="'submit'" :color="'green'">SPEICHERN</v-button>
          <v-button :type="'link'" :color="'light'" :to="{ name: 'files' }">ABBRECHEN</v-button>
        </form-box-buttons>
      </form-box-fields>
    </v-form>
  </div>
</template>

<script>
import FormComponents from '@/components/FormComponents'
import Button from '@/components/Button'

export default {
  components: {
    ...FormComponents,
    'v-button': Button,
  },
  data() {
    return {
      file: null,
      errors: {},
    }
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0]
    },
    submit() {
      let formData = new FormData()
      formData.append('file', this.file)

      this.$store
        .dispatch('files/store', formData)
        .then(this.$router.push({ name: 'files' }))
        .catch(err => console.log(err))
    },
  },
}
</script>
