<template>
  <div class="form-wrap" :style="`max-width: ${maxw}rem`">
    <form @submit.prevent="onSubmit">
      <slot></slot>
    </form>
  </div>
</template>

<script>
export default {
  name: 'VForm',
  props: {
    onSubmit: {
      type: Function,
    },
    maxw: {
      default: 48,
      type: Number,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.form-wrap {
  display: block;
  position: relative;

  form {
    display: inline-flex;
    position: relative;
    flex-direction: column;
    align-items: flex-start;
  }
}
</style>
