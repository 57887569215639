import axios from 'axios'

const getDefaultState = () => {
  return {
    contents: {
      ueberMich: '',
      settings: '',
      settings_aktuell: '',
      erstgespraech: '',
      schwerpunkte_kinder: '',
      schwerpunkte_eltern: '',
      schwerpunkte_erwachsene: '',
      methode: '',
      kontakt: '',
    },
  }
}

const state = getDefaultState()

const getters = {
  ueberMich: state => state.contents.ueberMich,
  settings: state => state.contents.settings,
  settings_aktuell: state => state.contents.settings_aktuell,
  erstgespraech: state => state.contents.erstgespraech,
  schwerpunkte_kinder: state => state.contents.schwerpunkte_kinder,
  schwerpunkte_eltern: state => state.contents.schwerpunkte_eltern,
  schwerpunkte_erwachsene: state => state.contents.schwerpunkte_erwachsene,
  methode: state => state.contents.methode,
  kontakt: state => state.contents.kontakt,
}

const mutations = {
  setAll: (state, contentsArray) => {
    contentsArray.forEach(({ field, content }) => {
      state.contents[field] = content
    })
  },
  setOne: (state, { field, content }) => (state.contents[field] = content),
}

const actions = {
  getAll: ({ commit }) => {
    return new Promise((resolve, reject) => {
      const url = `/contents`
      const config = {}
      axios
        .get(url, config)
        .then(response => {
          commit('setAll', response.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  getOne: ({ commit }, field) => {
    return new Promise((resolve, reject) => {
      const url = `/contents/${field}`
      const config = {}
      axios
        .get(url, config)
        .then(response => {
          commit('setOne', response.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
  patch: ({ commit, rootState }, { field, content }) => {
    return new Promise((resolve, reject) => {
      const data = { content }
      const url = `/admin/contents/${field}`
      axios
        .patch(url, data)
        .then(response => {
          commit('setOne', response.data.data)
          resolve()
        })
        .catch(err => reject(err))
    })
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
}
