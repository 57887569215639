var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Hilfe")]),_c('p',[_vm._v("Das CMS besteht aus drei Bereichen:")]),_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_c('p',[_vm._v(" Du hast für jedes der Textfelder einen Editor. Die meisten Funktionen des Editors sollten selbsterklärend sein, aber ein paar Dinge sollte ich erklären: ")]),_c('p',[_c('span',{staticClass:"menu-button"},[_c('ueberschrift-1-icon')],1),_vm._v(" Für Überschriften (wie bspw. „Auszüge aus meinem Lebenslauf“ in „Über Mich“). ")]),_c('p',[_c('span',{staticClass:"menu-button"},[_c('annotation-icon')],1),_vm._v(" Für kleingedruckten Text (wie bspw. die Supervision-Anmerkung in „Über Mich“). ")]),_c('p',[_c('span',{staticClass:"menu-button"},[_c('link-icon')],1),_vm._v(" Für Links im Fließtext (wie bspw. in „Über Mich“ und „Methode“). ")]),_c('p',[_c('span',{staticClass:"menu-button"},[_c('download-icon')],1),_vm._v(" Für Download-Links in einer eigenen Zeile (wie bspw. zu Infomaterial in „Settings - Aktuell“). ")]),_c('p',[_vm._v(" Wenn du einen Link (egal ob Fließtext-Link oder Download-Link) einfügen willst, dann schreibe zuerst den Text, als der der Link angezeigt werden soll (bspw. \"hier\"). Dann markiere diesen Text, klick auf "),_c('span',{staticClass:"menu-button"},[_c('link-icon')],1),_vm._v(" oder "),_c('span',{staticClass:"menu-button"},[_c('download-icon')],1),_vm._v(" und füge die URL ein. Die URL sollte mit \"https://\" oder \"//\" beginnen. ")]),_vm._m(5),_vm._m(6),_c('p',[_vm._v(" Zuerst lade das File im Bereich „Files“ hoch. Dann kopiere die generierte URL, die dir in der Übersicht angezeigt wird. Du kannst diese URL dann in der Textbearbeitung via "),_c('span',{staticClass:"menu-button"},[_c('link-icon')],1),_vm._v(" oder "),_c('span',{staticClass:"menu-button"},[_c('download-icon')],1),_vm._v(" verwenden. ")])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Account:")]),_vm._v(" Hier findest du Hilfe, Logout und die Möglichkeit, dein Passwort zu ändern. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Abschnitte:")]),_vm._v(" Hier kannst du neun verschiedene Textfelder auf deiner Website bearbeiten. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Files:")]),_vm._v(" Hier kannst du Dokumente (bspw.: PDFs) hochladen oder entfernen. ")])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Textbearbeitung")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('br')])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('strong',[_vm._v("Files")])])
}]

export { render, staticRenderFns }