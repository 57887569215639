<template>
  <div class="form-row-input">
    <label :for="name" :style="`width: ${lw}rem`"><slot></slot></label>
    <textarea
      :class="{ invalid: invalid }"
      :name="name"
      :placeholder="ph"
      :required="rqd"
      :autofocus="af"
      :style="`width: ${fw}rem`"
      :rows="rows"
      @input="updateValue($event.target.value)"
    ></textarea>
  </div>
</template>

<script>
export default {
  name: 'FormRowInput',
  props: {
    name: {
      default: '',
      type: String,
      required: true,
    },
    ph: {
      default: '',
      type: String,
    },
    rqd: {
      default: false,
      type: Boolean,
    },
    af: {
      default: false,
      type: Boolean,
    },
    rows: {
      default: 4,
      type: Number,
    },
    lw: {
      default: 12,
      type: Number,
    },
    fw: {
      default: 16,
      type: Number,
    },
    invalid: {
      default: false,
      type: Boolean,
    },
    error: {
      type: String,
    },
  },
  methods: {
    updateValue(value) {
      this.$emit('input', value)
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.form-row-input {
  display: grid;
  grid-template-columns: auto auto;
  grid-column-gap: 1rem;
  align-items: flex-start;
  margin-top: 2rem;

  &:first-child {
    margin-top: 0;
  }
}

label {
  justify-self: end;
  height: 2.25rem;
  width: 8rem;
  padding-top: 0.5rem;
  text-align: right;
}

textarea {
  padding: 0.5rem;
  border: 1px solid $col-gray-200;
  border-radius: 0.25rem;
  background: $col-white;
  font-size: 100%;
  transition: all ease 250ms;

  &::placeholder {
    color: $col-gray-600;
  }

  &:focus,
  &:active {
    border-color: $col-primary-400;
  }

  &.invalid,
  &.invalid:focus,
  &.invalid:active {
    border-color: $col-red-500;
  }
}
</style>
