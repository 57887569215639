<template>
    <svg viewBox="0 0 411 411">
        <title>
            <slot>Password</slot>
        </title>
        <path d="M336,114c-33.7,0-61.8,32.8-67.9,76.3H18.2A12.4,12.4,0,0,0,5.9,202.7v11.2a12.4,12.4,0,0,0,12.4,12.4H28.4v44.1a12.4,12.4,0,0,0,12.4,12.4h2.8a12.4,12.4,0,0,0,12.4-12.4V259.3h13v11.1a12.4,12.4,0,0,0,12.4,12.4H84a12.4,12.4,0,0,0,12.4-12.4V226.3H268c6.2,43.5,34.2,76.3,67.9,76.3,38.2,0,69.2-42.2,69.2-94.3S374.2,114,336,114Zm1.6,147.4c-19.7,0-35.7-23.8-35.7-53.1s16-53.1,35.7-53.1,35.7,23.8,35.7,53.1S357.3,261.4,337.6,261.4Z"/>
    </svg>
</template>

<script>
export default {
    name: 'MenuPasswordIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>