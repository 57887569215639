import { Node } from 'tiptap'
import { toggleWrap } from 'tiptap-commands'

export default class Smallprint extends Node {
    get name() { return 'smallprint' }

    get schema() {
        return {
            content: 'block*',
            group: 'block',
            defining: true,
            draggable: false,
            parseDOM: [
                { tag: 'sup' },
            ],
            toDOM: () => ['sup', 0],
        }
    }

    commands({ type, schema }) {
        return () => toggleWrap(type, schema.nodes.paragraph)
    }
}