<template>
  <div class="form-error" :style="`width: ${width}rem`" :class="`bg-${bg}`">
    <div class="bg"></div>
    <div class="message"><slot></slot></div>
  </div>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    width: {
      default: 31,
      type: Number,
    },
    bg: {
      default: 'light', // light / dark
      type: String,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.form-error {
  position: relative;
  margin-bottom: 1rem;
  border: 1px solid $col-red-500;
  border-radius: 0.25rem;

  .bg {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: $col-red-500;
    opacity: 0.05;
    border-radius: 0.25rem;
  }

  .message {
    position: relative;
    height: 100%;
    width: 100%;
    padding: 1rem;
    color: $col-red-700;
    line-height: $leading-small;
    border-radius: 0.25rem;
  }

  &.bg-dark {
    .bg {
      background-color: $col-red-900;
      opacity: 0.25;
    }

    .message {
      color: $col-red-500;
    }
  }
}
</style>
