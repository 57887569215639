import { Mark, Plugin } from 'tiptap'
import { updateMark, removeMark } from 'tiptap-commands'
import { getMarkAttrs } from 'tiptap-utils'

export default class Download extends Mark {
    get name() { return 'download' }

    get defaultOptions() {
        return {
            openOnClick: true,
        }
    }

    get schema() {
        return {
            attrs: {
                href: { default: null, },
                class: { default: 'download', },
            },
            inclusive: false,
            parseDOM: [
                {
                    tag: 'a.download[href]',
                    getAttrs: dom => ({
                        href: dom.getAttribute('href'),
                        class: dom.getAttribute('class'),
                    }),
                },
            ],
            toDOM: node => ['a', {
                ...node.attrs,
                rel: 'noopener noreferrer nofollow',
                target: '_blank',
            }, 0],
        }
    }

    commands({ type }) {
        return attrs => {
            if (attrs.href) {
                return updateMark(type, attrs)
            }

            return removeMark(type)
        }
    }

    get plugins() {
        if (!this.options.openOnClick) {
            return []
        }

        return [
            new Plugin({
                props: {
                    handleClick: (view, pos, event) => {
                        const { schema } = view.state
                        const attrs = getMarkAttrs(view.state, schema.marks.download)

                        if (attrs.href && event.target instanceof HTMLAnchorElement) {
                            event.stopPropagation()
                            window.open(attrs.href)
                        }
                    },
                },
            }),
        ]
    }
}