<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Paragraph</slot>
        </title>
        <path d="M43.82,16.18v1.35c-3.79.38-4.32.49-4.32,4.75V47.82h-2V18H34.36V39.82h0v8h-2V34.9h-1.6A12,12,0,0,1,25,33.45c-.34-.19-.68-.4-1-.62a9.14,9.14,0,0,1-3.85-7.76,8.06,8.06,0,0,1,3.17-6.81,4.93,4.93,0,0,1,.68-.44,14.36,14.36,0,0,1,7.43-1.64Z"/>
    </svg>
</template>

<script>
export default {
    name: 'ParagraphIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>