const state = {
    width: null,
    height: null,
}

const getters = {
    width: state => state.width,
    height: state => state.height,
    ratio: state => state.width / state.height,
    device: state => state.width > 768 ? 'desktop' : 'mobile',
    isMobile: (state, getters) => getters.device === 'mobile',
    isDesktop: (state, getters) => getters.device === 'desktop',
    layout: (state, getters) => getters,
}

const mutations = {
    setWidth: (state, value) => state.width = value,
    setHeight: (state, value) => state.height = value,
}

const actions = {
    measure: ({ commit }) => {
        commit('setWidth', document.body.offsetWidth)
        commit('setHeight', document.body.offsetHeight)
    },
}

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
}