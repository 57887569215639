<template>
  <div id="app" class="app-wrap">
    <mobile-menu-component v-if="isAuth && isMobile"></mobile-menu-component>
    <desktop-menu-component v-if="isAuth && isDesktop"></desktop-menu-component>

    <router-view :class="{ desktop: isAuth && isDesktop }"></router-view>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import MobileMenuComponent from '@/components/MobileMenuComponent'
import DesktopMenuComponent from '@/components/DesktopMenuComponent'

export default {
  name: 'App',
  components: {
    MobileMenuComponent,
    DesktopMenuComponent,
  },
  computed: {
    ...mapGetters('auth', ['isAuth']),
    ...mapGetters('layout', ['isMobile', 'isDesktop']),
  },
  created() {
    this.measure()
    window.addEventListener('resize', this.measure)
  },
  methods: {
    measure() {
      this.$store.dispatch('layout/measure')
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/app';

.desktop {
  margin-left: 20rem;
}
</style>
