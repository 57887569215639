<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Fett</slot>
        </title>
        <path d="M42.47,24.34a12.73,12.73,0,0,0-2.12-4.51c-1-1.06-1.63-1.25-3.93-1.25h-3.6c-1.63,0-1.83.1-1.83,1.73V30.77h5.14c2.83,0,3.22-.72,3.94-4.08h1.77V37.35H40c-.53-3.26-1.25-4.08-3.89-4.08H31v7.49c0,4.42.29,4.75,4.61,5v1.78H20.24V45.8c3.65-.34,4.08-.62,4.08-5V23.09c0-4.27-.43-4.65-4.08-4.94V16.37H36.51c4.13,0,6.53,0,7.3-.09,0,.81.29,4.94.53,7.77Z"/>
    </svg>
</template>

<script>
export default {
    name: 'FettIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>