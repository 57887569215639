<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Block Quote</slot>
        </title>
        <path d="M18.54,38.84c4.75-1.73,6.62-4,6.62-6.12,0-2.66-2.59-3.6-5.47-4.25-.51-.14-1.23-.86-1.23-2.52,0-1.94,1.66-4.18,4.83-4.18,3.6,0,7.42,2.81,7.42,8.29,0,6.48-5.62,10-11.09,11.44Zm14.83,0c4.75-1.73,6.62-4,6.62-6.12,0-2.66-2.59-3.6-5.47-4.25-.5-.14-1.22-.86-1.22-2.52,0-1.94,1.65-4.18,4.82-4.18,3.6,0,7.42,2.81,7.42,8.29,0,6.48-5.62,10-11.09,11.44Z"/>
    </svg>
</template>

<script>
export default {
    name: 'BlockQuoteIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>