<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Download</slot>
        </title>
        <path d="M47.39,51H16.61a.61.61,0,0,1-.61-.61V48.61a.61.61,0,0,1,.61-.61H47.39a.61.61,0,0,1,.61.61v1.78A.61.61,0,0,1,47.39,51ZM40.82,30.1A43.31,43.31,0,0,1,33.5,31V12.5A.5.5,0,0,0,33,12H31a.5.5,0,0,0-.5.5V31a43.31,43.31,0,0,1-7.32-.89c-1.11-.26-1.51,0-.89.61,3.33,3.34,7.45,10.22,9,13a.7.7,0,0,0,1.34,0c1.59-2.77,5.71-9.65,9-13C42.33,30.1,41.93,29.84,40.82,30.1Z"/>
    </svg>
</template>

<script>
export default {
    name: 'DownloadIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>