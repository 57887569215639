<template>
  <button
    v-if="type == 'button' || type == 'submit'"
    :type="type"
    :class="`${color} bg-${bg}`"
    :style="`min-width: ${minWidth}rem`"
  >
    <span>
      <slot>Button</slot>
    </span>
  </button>

  <router-link v-else :to="to" :class="`${color} bg-${bg}`" :style="`min-width: ${minWidth}rem`">
    <span>
      <slot>Button</slot>
    </span>
  </router-link>
</template>

<script>
export default {
  name: 'VButton',
  props: {
    type: {
      default: 'button', // button / submit / link
      type: String,
    },
    color: {
      default: 'dark', // dark / light / green / red
      type: String,
    },
    bg: {
      default: 'light', // light / dark
      type: String,
    },
    minWidth: {
      default: 10,
      type: Number,
    },
    to: {
      type: Object,
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

button,
a {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 2.125rem;
  padding: 0.625rem 0.75rem;
  border-radius: 0.25rem;
  transition: all ease 250ms;
  cursor: pointer;

  &:not(:last-child) {
    margin-right: 2rem;
  }

  &:hover,
  &:focus {
    transform: scale(1.1);
  }

  &.dark {
    background: $col-gray-800;

    &:hover,
    &:focus {
      background: $col-black;
    }
  }

  &.light {
    background: $col-gray-500;

    &:hover,
    &:focus {
      background: $col-gray-600;
    }
  }

  &.green {
    background: $col-primary-500;

    &:hover,
    &:focus {
      background: $col-primary-600;
    }

    &.bg-dark {
      background: $col-primary-400;

      &:hover,
      &:focus {
        background: $col-primary-500;
      }
    }
  }

  &.red {
    background: $col-red-500;

    &:hover,
    &:focus {
      background: $col-red-600;
    }
  }

  span {
    padding-top: 0.1875em;
    color: $col-white;
    font-size: 0.875rem;
    font-weight: 600;
    letter-spacing: $tracking-widest;
  }

  &.bg-dark span {
    color: $col-gray-900;
  }
}
</style>
