<template>
  <div>
    <overview-text-box :title="'Hauptartikel'">
      <div class="cms" v-html="settings"></div>

      <v-button class="button" :type="'link'" :to="{ name: 'abschnitte.settings.edit-body' }">
        Bearbeiten
      </v-button>
    </overview-text-box>

    <overview-text-box :title="'Aktuell'">
      <div class="cms" v-html="settings_aktuell"></div>

      <v-button class="button" :type="'link'" :to="{ name: 'abschnitte.settings.edit-aktuell' }">
        Bearbeiten
      </v-button>
    </overview-text-box>
  </div>
</template>

<script>
import OverviewTextBox from '@/components/OverviewTextBox'
import Button from '@/components/Button'

export default {
  components: {
    OverviewTextBox,
    'v-button': Button,
  },
  computed: {
    settings() {
      return this.$store.getters['contents/settings']
    },
    settings_aktuell() {
      return this.$store.getters['contents/settings_aktuell']
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  margin-top: 2rem;
}
</style>
