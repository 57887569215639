<template>
  <div></div>
</template>

<script>
export default {
  async created() {
    await this.$store.dispatch('auth/logout')
    this.$router.push({ name: 'login' })
  },
}
</script>
