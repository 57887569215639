<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Kursiv</slot>
        </title>
        <path d="M49,17.72c-3.6.53-4.47.86-7.54,3.65-2.74,2.44-6.48,5.71-10.27,9.12,2.45,3.88,6.53,9.74,8.69,12.67,1.82,2.45,2.64,2.74,5.71,3.07l-.39,1.35H37.86c-3-4.42-6.34-9.61-8.93-13.54-1.06-1.63-1.58-1.87-2.88-1.78l-1.68,9.12c-.72,4.28-.62,4.47,3.7,4.85l-.29,1.35H15l.33-1.35c3.84-.33,4.32-.57,5.09-4.85l3.7-19.05c.77-3.94.48-4.23-3.65-4.61l.29-1.35H33.44l-.29,1.35c-3.93.33-4.41.62-5.18,4.61l-1.63,8.54a5.57,5.57,0,0,0,3.07-1.3A128.15,128.15,0,0,0,39.73,20c1.2-1.34,1-1.82-.67-2.06l-1.49-.19.34-1.35H49.33Z"/>
    </svg>
</template>

<script>
export default {
    name: 'KursivIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>