import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'

import PageNotFound from '../views/PageNotFound.vue'

import Account from '../views/Account/View.vue'

import Login from '../views/Login.vue'
import Help from '../views/Account/Help.vue'
import ChangePassword from '../views/Account/ChangePassword.vue'
import Logout from '../views/Account/Logout.vue'

import Abschnitte from '../views/Abschnitte/View.vue'

import UeberMich from '../views/Abschnitte/UeberMich/View.vue'
import UeberMichOverview from '../views/Abschnitte/UeberMich/Overview.vue'
import UeberMichEdit from '../views/Abschnitte/UeberMich/Edit.vue'

import Settings from '../views/Abschnitte/Settings/View.vue'
import SettingsOverview from '../views/Abschnitte/Settings/Overview.vue'
import SettingsEditBody from '../views/Abschnitte/Settings/EditBody.vue'
import SettingsEditAktuell from '../views/Abschnitte/Settings/EditAktuell.vue'

import Erstgespraech from '../views/Abschnitte/Erstgespraech/View.vue'
import ErstgespraechOverview from '../views/Abschnitte/Erstgespraech/Overview.vue'
import ErstgespraechEdit from '../views/Abschnitte/Erstgespraech/Edit.vue'

import Schwerpunkte from '../views/Abschnitte/Schwerpunkte/View.vue'
import SchwerpunkteOverview from '../views/Abschnitte/Schwerpunkte/Overview.vue'
import SchwerpunkteEditKinder from '../views/Abschnitte/Schwerpunkte/EditKinder.vue'
import SchwerpunkteEditEltern from '../views/Abschnitte/Schwerpunkte/EditEltern.vue'
import SchwerpunkteEditErwachsene from '../views/Abschnitte/Schwerpunkte/EditErwachsene.vue'

import Methode from '../views/Abschnitte/Methode/View.vue'
import MethodeOverview from '../views/Abschnitte/Methode/Overview.vue'
import MethodeEdit from '../views/Abschnitte/Methode/Edit.vue'

import Kontakt from '../views/Abschnitte/Kontakt/View.vue'
import KontaktOverview from '../views/Abschnitte/Kontakt/Overview.vue'
import KontaktEdit from '../views/Abschnitte/Kontakt/Edit.vue'

import Files from '../views/Files/View.vue'
import FilesOverview from '../views/Files/Overview.vue'
import FilesUpload from '../views/Files/Upload.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '',
    name: 'home',
    redirect: { name: 'account' },
    meta: { requiresAuth: true },
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: { requiresGuest: true },
  },
  {
    path: '/account',
    name: 'account',
    component: Account,
    redirect: { name: 'account.help' },
    meta: { requiresAuth: true },
    children: [
      {
        path: 'hilfe',
        name: 'account.help',
        component: Help,
      },
      {
        path: 'passwort-aendern',
        name: 'account.change-password',
        component: ChangePassword,
      },
      {
        path: 'logout',
        name: 'account.logout',
        component: Logout,
      },
    ],
  },
  {
    path: '/abschnitte',
    name: 'abschnitte',
    component: Abschnitte,
    redirect: { name: 'abschnitte.ueber-mich' },
    meta: { requiresAuth: true },
    children: [
      {
        path: 'ueber-mich',
        component: UeberMich,
        children: [
          {
            path: '',
            name: 'abschnitte.ueber-mich',
            component: UeberMichOverview,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.ueber-mich.edit',
            component: UeberMichEdit,
          },
        ],
      },
      {
        path: 'settings',
        component: Settings,
        children: [
          {
            path: '',
            name: 'abschnitte.settings',
            component: SettingsOverview,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.settings.edit-body',
            component: SettingsEditBody,
          },
          {
            path: 'aktuell/bearbeiten',
            name: 'abschnitte.settings.edit-aktuell',
            component: SettingsEditAktuell,
          },
        ],
      },
      {
        path: 'erstgespraech',
        component: Erstgespraech,
        children: [
          {
            path: '',
            name: 'abschnitte.erstgespraech',
            component: ErstgespraechOverview,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.erstgespraech.edit',
            component: ErstgespraechEdit,
          },
        ],
      },
      {
        path: 'schwerpunkte',
        component: Schwerpunkte,
        children: [
          {
            path: '',
            name: 'abschnitte.schwerpunkte',
            component: SchwerpunkteOverview,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.schwerpunkte.edit-kinder',
            component: SchwerpunkteEditKinder,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.schwerpunkte.edit-eltern',
            component: SchwerpunkteEditEltern,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.schwerpunkte.edit-erwachsene',
            component: SchwerpunkteEditErwachsene,
          },
        ],
      },
      {
        path: 'methode',
        component: Methode,
        children: [
          {
            path: '',
            name: 'abschnitte.methode',
            component: MethodeOverview,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.methode.edit',
            component: MethodeEdit,
          },
        ],
      },
      {
        path: 'kontakt',
        component: Kontakt,
        children: [
          {
            path: '',
            name: 'abschnitte.kontakt',
            component: KontaktOverview,
          },
          {
            path: 'bearbeiten',
            name: 'abschnitte.kontakt.edit',
            component: KontaktEdit,
          },
        ],
      },
    ],
  },
  {
    path: '/dateien',
    component: Files,
    meta: { requiresAuth: true },
    children: [
      {
        path: '',
        name: 'files',
        component: FilesOverview,
      },
      {
        path: 'upload',
        name: 'files.upload',
        component: FilesUpload,
      },
    ],
  },
  {
    path: '*',
    name: '404',
    component: PageNotFound,
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
  scrollBehavior(to, _from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.getters['auth/isAuth']) next({ name: 'login' })
    else next()
  } else if (to.matched.some(record => record.meta.requiresGuest)) {
    if (store.getters['auth/isAuth']) next({ name: 'home' })
    else next()
  } else {
    next()
  }
})

export default router
