<template>
  <svg class="cms-icon" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.1 101">
    <title><slot>CMS-Icon</slot></title>
    <g>
      <path
        d="M231.9,225.9h-36c-10,0-15,5-21,18-3.8,8.5-5.7,17.9-1,25,2,3,7,3,11,3h47"
        transform="translate(-169.6 -172.4)"
      />
      <path
        d="M231.4,225.9h36c10,0,15,5,21,18,3.8,8.5,5.7,17.9,1,25-2,3-7,3-11,3h-47"
        transform="translate(-169.6 -172.4)"
      />
    </g>
    <rect x="26.8" y="1.5" width="70" height="52" />
    <line x1="39.5" y1="80.6" x2="85" y2="80.6" />
    <line x1="22.6" y1="80.6" x2="28.4" y2="80.6" />
    <line x1="15.7" y1="72.2" x2="21.5" y2="72.2" />
    <line x1="30.2" y1="72.2" x2="36.1" y2="72.2" />
    <line x1="44.8" y1="72.2" x2="50.7" y2="72.2" />
    <line x1="59.3" y1="72.2" x2="65.2" y2="72.2" />
    <line x1="73.9" y1="72.2" x2="79.8" y2="72.2" />
    <line x1="88.5" y1="72.2" x2="94.3" y2="72.2" />
    <line x1="103" y1="72.2" x2="108.9" y2="72.2" />
    <line x1="22.9" y1="64.1" x2="28.8" y2="64.1" />
    <line x1="37.5" y1="64.1" x2="43.4" y2="64.1" />
    <line x1="52.1" y1="64.1" x2="57.9" y2="64.1" />
    <line x1="66.6" y1="64.1" x2="72.5" y2="64.1" />
    <line x1="81.2" y1="64.1" x2="87.1" y2="64.1" />
    <line x1="95.7" y1="64.1" x2="101.6" y2="64.1" />
    <line x1="96.1" y1="80.6" x2="102" y2="80.6" />
    <g>
      <line x1="109.4" y1="57.3" x2="121.3" y2="57.3" />
      <line x1="121.3" y1="50.5" x2="121.3" y2="64" />
    </g>
    <g>
      <line x1="14.5" y1="57.3" x2="2.6" y2="57.3" />
      <line x1="2.6" y1="50.5" x2="2.6" y2="64" />
    </g>
    <line x1="56.7" y1="52.6" x2="56.7" y2="47.6" />
    <line x1="67.9" y1="52.6" x2="67.9" y2="47.6" />
  </svg>
</template>

<script>
export default {
  name: 'CmsIcon',
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

svg {
  display: block;
  fill: none;
  stroke-width: 3;
  stroke-linecap: round;
  stroke-linejoin: round;
}
</style>
