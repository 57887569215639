<template>
  <div class="login-view">
    <div class="center">
      <cms-icon class="cms-icon"></cms-icon>

      <v-form class="form" :onSubmit="login">
        <form-error v-if="error" :width="16" :bg="'dark'">{{ error }}</form-error>

        <form-row-input
          :name="'email'"
          :type="'email'"
          :label="false"
          :ph="'E-Mail-Adresse'"
          :rqd="true"
          :af="true"
          :bg="'dark'"
          :invalid="error"
          v-model="credentials.email"
        ></form-row-input>

        <form-row-input
          :name="'password'"
          :type="'password'"
          :label="false"
          :ph="'Passwort'"
          :rqd="true"
          :bg="'dark'"
          :invalid="error"
          v-model="credentials.password"
        ></form-row-input>

        <form-box-buttons>
          <v-button :type="'submit'" :color="'green'" :bg="'dark'" :minWidth="8">LOGIN</v-button>
        </form-box-buttons>
      </v-form>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

import FormComponents from '@/components/FormComponents'
import Button from '@/components/Button'

import CmsIcon from '@/components/svg/CmsIcon'

export default {
  components: {
    ...FormComponents,
    'v-button': Button,
    CmsIcon,
  },
  data() {
    return {
      credentials: {
        email: '',
        password: '',
      },
      error: false,
    }
  },
  methods: {
    ...mapActions({
      loginAction: 'auth/login',
    }),
    async login() {
      try {
        await this.loginAction(this.credentials)
        this.$router.push({ name: 'home' })
      } catch (e) {
        this.error = true
        this.credentials.password = ''
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.login-view {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: $col-gray-900;
}

.center {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  align-items: center;
}

.cms-icon {
  height: 6rem;
  stroke: $col-primary-400;
}

.form {
  margin-top: 4rem;
}

.form-row {
  width: 16rem;
  grid-template-columns: auto;

  &--btn {
    justify-items: center;
  }
}

.input {
  color: $col-gray-200;
  background: $col-black;
  border-color: $col-gray-700;

  &:focus,
  &:active {
    border-color: $col-primary-400;
  }
}
</style>
