<template>
  <div class="modal-component" v-if="modalVisible">
    <div class="overlay" @click="hideModal"></div>

    <div class="modal">
      <h3>
        <slot name="title"></slot>
      </h3>
      <slot></slot>
      <button class="close" @click="hideModal">
        <close-icon>Popup schließen</close-icon>
      </button>
    </div>
  </div>
</template>

<script>
import CloseIcon from './svg/CloseIcon'

export default {
  name: 'ModalComponent',
  components: {
    CloseIcon,
  },
  created() {
    document.addEventListener('keyup', this.handleGlobalKeyupEvent)
  },
  data() {
    return {
      modalVisible: false,
    }
  },
  methods: {
    handleGlobalKeyupEvent() {
      if (event.keyCode === 27) this.hideModal()
    },
    showModal() {
      this.modalVisible = true
    },
    hideModal() {
      this.modalVisible = false
    },
  },
  beforeDestroy() {
    document.removeEventListener('keyup', this.handleGlobalKeyupEvent)
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

.modal-component {
  z-index: 600;

  .overlay {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.2);
    z-index: 1;
  }

  .modal {
    position: fixed;
    max-height: calc(100vh - 8rem);
    max-width: calc(100vw - 8rem);
    top: 50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
    padding: 3rem 6rem;
    background: $col-white;
    border-radius: 1rem;
    box-shadow: 4px 16px 24px rgba(0, 0, 0, 0.2);
    z-index: 2;

    h3 {
      margin-bottom: 3rem;
      font-family: $font-sans;
      font-size: 1.5rem;
      font-weight: 600;
      letter-spacing: $tracking-wide;
      text-align: center;
    }

    .close {
      position: absolute;
      height: 2rem;
      top: 1rem;
      right: 1rem;
      border-radius: 1rem;

      &:hover {
        background: $col-gray-100;
      }
    }
  }
}
</style>
