import axios from 'axios'

export default {
  namespaced: true,

  state: {
    authenticated: false,
    user: null,
  },

  getters: {
    isAuth: state => state.authenticated,
    isGuest: state => !state.authenticated,
    user: state => state.user,
  },

  mutations: {
    SET_AUTHENTICATED(state, value) {
      state.authenticated = value
    },
    SET_USER(state, value) {
      state.user = value
    },
  },

  actions: {
    async login({ dispatch }, credentials) {
      await axios.get('/sanctum/csrf-cookie')
      await axios.post('/login', credentials)

      return dispatch('getUser')
    },
    async logout({ dispatch }) {
      await axios.post('/logout')

      return dispatch('getUser')
    },
    getUser({ commit }) {
      return axios
        .get('/user')
        .then(response => {
          commit('SET_AUTHENTICATED', true)
          commit('SET_USER', response.data)
        })
        .catch(() => {
          commit('SET_AUTHENTICATED', false)
          commit('SET_USER', null)
        })
    },
    updatePassword(_, formData) {
      return new Promise((resolve, reject) => {
        const url = '/user/password'
        axios
          .put(url, formData)
          .then(() => resolve())
          .catch(err => reject(err))
      })
    },
  },
}
