<template>
  <div>
    <overview-text-box :title="'Hauptartikel'">
      <div class="cms" v-html="kontakt"></div>

      <v-button class="button" :type="'link'" :to="{ name: 'abschnitte.kontakt.edit' }">
        Bearbeiten
      </v-button>
    </overview-text-box>
  </div>
</template>

<script>
import OverviewTextBox from '@/components/OverviewTextBox'
import Button from '@/components/Button'

export default {
  components: {
    OverviewTextBox,
    'v-button': Button,
  },
  computed: {
    kontakt() {
      return this.$store.getters['contents/kontakt']
    },
  },
}
</script>

<style lang="scss" scoped>
.button {
  margin-top: 2rem;
}
</style>
