<template>
  <div class="mobile-menu-component">
    <nav>
      <button class="menu-button" @click="toggleMenu">
        <close-menu-icon v-show="menuOpen">Menü schließen</close-menu-icon>
        <menu-icon v-show="!menuOpen">Menü</menu-icon>
      </button>

      <ul class="menu menu--1" :class="{ open: menuOpen }">
        <li class="menu__item menu__item--1" v-for="menuItem in MenuItems" :key="menuItem.id">
          <a class="menu__link menu__link--1" @click.prevent="handleTopLevelClick(menuItem)">
            {{ menuItem.title }}
          </a>

          <ul v-if="currentList == menuItem.title" class="menu menu--2">
            <li
              class="menu__item menu__item--2"
              v-for="subroute in menuItem.subroutes"
              :key="subroute.id"
            >
              <router-link
                class="menu__link menu__link--2"
                :to="{ name: subroute.route }"
                @click.native="closeMenu"
              >
                {{ subroute.title }}
              </router-link>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import MenuItems from './MenuItems'

import MenuIcon from './svg/MenuIcon'
import CloseMenuIcon from './svg/CloseMenuIcon'

export default {
  name: 'MobileMenuComponent',
  components: {
    MenuIcon,
    CloseMenuIcon,
  },
  data() {
    return {
      menuOpen: false,
      MenuItems,
      currentList: '',
    }
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen
    },
    closeMenu() {
      this.menuOpen = false
    },
    handleTopLevelClick(menuItem) {
      if (menuItem.subroutes) {
        this.toggleList(menuItem.title)
      } else {
        this.$router.push({ name: menuItem.route })
        this.closeMenu()
      }
    },
    toggleList(title) {
      if (this.currentList === title) {
        this.currentList = ''
      } else {
        this.currentList = title
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/_variables.scss';

nav {
  position: fixed;
  top: 0;
  left: 100%;
  z-index: 400;
}

.menu-button {
  display: grid;
  align-items: center;
  justify-items: center;
  position: fixed;
  height: 2.5rem;
  width: 2.5rem;
  top: 2rem;
  right: 2rem;
  background: $col-primary-400;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;

  svg {
    height: 2rem;
    fill: $col-black;
  }
}

.menu--1 {
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: $col-white;
  background: $col-gray-900;
  transform: translateX(100%);
  transition: transform 0.5s ease;

  &.open {
    transform: translateX(0);
  }
}

.menu__item {
  &--1:not(:first-child) {
    margin-top: 2rem;
  }
}

.menu__link {
  display: block;
  padding: 0.75rem 0;
  color: $col-gray-200;
  text-align: center;
  cursor: pointer;

  &--1 {
    font-size: 1.5rem;
  }

  &--2 {
    font-size: 1rem;
  }
}
</style>
