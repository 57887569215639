<template>
  <div>
    <v-form :onSubmit="submit">
      <form-box-fields :title="'Aktuell'">
        <form-error v-if="Object.entries(errors).length !== 0">
          Bitte überprüfe deine Eingaben.
        </form-error>

        <form-row-tiptap v-model="settings_aktuell"></form-row-tiptap>

        <form-box-buttons>
          <v-button :type="'submit'" :color="'green'">SPEICHERN</v-button>
          <v-button :type="'link'" :color="'light'" :to="{ name: 'abschnitte.settings' }">
            ABBRECHEN
          </v-button>
        </form-box-buttons>
      </form-box-fields>
    </v-form>
  </div>
</template>

<script>
import FormComponents from '@/components/FormComponents'
import Button from '@/components/Button'

export default {
  components: {
    ...FormComponents,
    'v-button': Button,
  },
  data() {
    return {
      settings_aktuell: '',
      errors: {},
    }
  },
  created() {
    this.settings_aktuell = this.$store.getters['contents/settings_aktuell']
  },
  methods: {
    submit() {
      this.$store
        .dispatch('contents/patch', {
          field: 'settings_aktuell',
          content: this.settings_aktuell,
        })
        .then(this.$router.push({ name: 'abschnitte.settings' }))
    },
  },
}
</script>
