<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Überschrift 3</slot>
        </title>
        <path d="M43.77,21.05C41,21.24,40,22,39.92,23.92c-.06,1.06-.14,2.85-.14,6.72v4.94c0,4.36-.55,7.86-3.09,10.54C34.63,48.27,31.61,49,28.56,49a12,12,0,0,1-7.34-2.08c-2.62-2-3.89-5-3.89-10.09V25.61c0-4-.36-4.25-3.85-4.56v-1.3H25.42v1.3c-3.48.24-3.84.58-3.84,4.56V36.74c0,6.15,2.92,9.62,8.1,9.62,6.07,0,8.11-3.87,8.11-11V30.64c0-3.86-.14-5.52-.23-6.73-.13-2-1.19-2.68-4.41-2.86v-1.3H43.77ZM27.05,14a2.4,2.4,0,0,1-2.38,2.4,2.44,2.44,0,0,1,0-4.88A2.4,2.4,0,0,1,27.05,14ZM36,14a2.42,2.42,0,1,1-2.42-2.48A2.42,2.42,0,0,1,36,14Zm8.24,26.14,1.31-1.76a4.89,4.89,0,0,1,3.61-1.6c2.55,0,4.23,1.6,4.23,3.72a3,3,0,0,1-.87,2.08,11.62,11.62,0,0,1-2,1.51c2.6.56,4.14,2.27,4.14,4.31,0,4.56-5.38,6.5-7.36,6.5a4.57,4.57,0,0,1-2.92-1,1.29,1.29,0,0,1-.42-.93,1.65,1.65,0,0,1,.65-1.06.52.52,0,0,1,.73,0,5.12,5.12,0,0,0,3.47,1.48c2,0,3.44-1.51,3.44-4.23a3.43,3.43,0,0,0-3.69-3.58,7.09,7.09,0,0,0-1.88.31l-.2-.84C49,44.23,51,43.11,51,41a2.45,2.45,0,0,0-2.69-2.6,4.51,4.51,0,0,0-3.5,2.21Z"/>
    </svg>
</template>

<script>
export default {
    name: 'Ueberschrift3Icon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>