<template>
    <svg viewBox="0 0 64 64">
        <title>
            <slot>Anmerkung</slot>
        </title>
        <path d="M33.11,32c6.81-3.58,15.22-3.83,12.82-8s-6.86,3-13.4,7.14C32.82,23.44,36.82,16,32,16s-.82,7.44-.53,15.14c-6.54-4.1-11-11.32-13.4-7.14s6,4.42,12.82,8c-6.81,3.58-15.22,3.83-12.82,8s6.86-3,13.4-7.14C31.18,40.56,27.18,48,32,48s.82-7.44.53-15.14c6.54,4.1,11,11.32,13.4,7.14S39.92,35.58,33.11,32Z"/>
    </svg>
</template>

<script>
export default {
    name: 'AnnotationIcon',
}
</script>

<style lang="scss" scoped>
svg {
    display: block;
    height: 100%;
}
</style>